<template>
  <div
    class="recipe-detail-filter pa-5 accent rounded-md"
    v-if="coursesData && cuisineData"
  >
    <h4 class="text-uppercase default--text mb-3">
      {{ $t("recipes.filter.title") }}
    </h4>
    <v-form ref="form" v-model="valid" @submit.prevent="searchRecipe">
      <v-row no-gutters class=" align-center">
        <v-col cols="12" sm="12" md="3" class="mb-3 mr-md-3 white">
          <v-text-field
            v-model="s"
            class="rounded-0"
            clearable
            hide-details
            color="primary"
            append-icon="$search"
            :label="$t('recipes.filter.freeSearch')"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="mb-3 mr-md-3">
          <v-select
            clearable
            v-model="course"
            :items="coursesList"
            class="white default--text rounded-0 mb-3 mb-md-0"
            append-icon="$chevronDown"
            single-line
            outlined
            :label="$t('recipes.filter.portata')"
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="mb-3 mr-md-3">
          <v-select
            clearable
            v-model="cuisine"
            :items="cuisineList"
            class="white default--text rounded-0 mb-3 mb-md-0"
            append-icon="$chevronDown"
            single-line
            outlined
            :label="$t('recipes.filter.tipology')"
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="mb-3 mr-md-3">
          <v-select
            clearable
            v-model="video"
            :items="videoList"
            class="white default--text rounded-0 mb-3 mb-md-0"
            append-icon="$chevronDown"
            single-line
            outlined
            :label="$t('recipes.filter.videoRecipe')"
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="mb-3">
          <v-btn
            type="submit"
            class="rounded-pill elevation-0 pa-6 w-100 default--text"
            color="primary"
            >{{ $t("cta.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss">
.recipe-detail-filter {
  h2 {
    line-height: inherit;
  }
  .v-text-field fieldset {
    border: 0 !important;
  }
  .v-text-field .v-icon {
    color: var(--v-default-base);
  }
  .v-text-field.v-select .v-icon {
    color: var(--v-primary-base);
  }
}
</style>

<script>
import cmService from "~/service/cmService";

export default {
  name: "RecipeFilter",
  data() {
    return {
      s: null,
      course: null,
      coursesData: null,
      cuisine: null,
      cuisineData: null,
      video: null,
      videoData: null,
      valid: true
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  computed: {
    coursesList() {
      let tempArr = [];
      for (var i = 0; i < this.coursesData.length; i++) {
        tempArr.push(this.coursesData[i].name);
      }
      return tempArr;
    },
    cuisineList() {
      let tempArr = [];
      if (this.cuisineData) {
        for (var i = 0; i < this.cuisineData.length; i++) {
          tempArr.push(this.cuisineData[i].name);
        }
      }
      return tempArr;
    },
    videoList() {
      let tempArr = [];
      if (this.videoData) {
        for (var i = 0; i < this.videoData.length; i++) {
          tempArr.push(this.videoData[i].name);
        }
      }
      return tempArr;
    }
  },
  methods: {
    async fetchCategories() {
      const categories = await cmService.getCustomPostByFilters(
        "categoria_wlt",
        null,
        100
      );
      console.log(categories);
    },
    async fetchWpDataCourse() {
      const result = await cmService.getCustomPostBySlug("course");
      if (result && result.length) {
        this.coursesData = result;
      }
    },
    async fetchWpDataCuisine() {
      const result = await cmService.getCustomPostBySlug("cuisine");
      if (result && result.length) {
        this.cuisineData = result;
      }
    },
    async fetchWpDataVideo() {
      const result = await cmService.getCustomPostBySlug("chefs");
      if (result && result.length) {
        this.videoData = result;
      }
    },
    searchRecipe() {
      let query = {};
      if (this.s != null && this.s.length > 0) {
        query["s"] = this.s;
      }
      if (this.course != null && this.course.length > 0) {
        query["course"] = this.course;
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        query["cuisine"] = this.cuisine;
      }
      if (this.video != null && this.video.length > 0) {
        query["video"] = this.video;
      }
      if (this.changePage == "true") {
        this.$router.push({ name: "RecipeList", query });
      } else {
        this.$router.replace({ query });
        let prova = this.$route.query;
        console.log("PROVA", prova);
      }
    }
  },
  mounted() {
    this.fetchCategories();
    this.coursesData = this.fetchWpDataCourse();
    this.cuisineData = this.fetchWpDataCuisine();
    this.videoData = this.fetchWpDataVideo();
  }
};
</script>
