<template>
  <v-container class="wp-page">
    <!-- TODO - BREADCRUMB  -->
    <v-row>
      <v-col cols="12">
        <h1 class="mb-3">{{ $t("recipes.title") }}</h1>
        <p
          v-if="page && page.content && page.content.rendered"
          v-html="page.content.rendered"
        ></p>
        <RecipeFilter changePage="true" />
      </v-col>
      <v-col cols="12" v-if="postList != null">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="recipe in postList"
            :key="recipe.id"
          >
            <PostCard :post="recipe" type="recipe" />
          </v-col>
        </v-row>
        <v-pagination
          class="mt-12"
          color="primary"
          v-if="pager && pager.totPages > 1"
          v-model="pageNum"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>
      </v-col>
      <v-col v-if="noPostToShow">
        {{ $t("recipes.noPostToShow") }}
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";
import page from "~/mixins/page";
import get from "lodash/get";

import PostCard from "@/components/wordpress/PostCard.vue";
import RecipeFilter from "@/components/wordpress/RecipeFilter.vue";
export default {
  name: "RecipeList",
  mixins: [page, categoryMixins, clickHandler],
  components: {
    PostCard,
    RecipeFilter
  },
  props: {
    s: {
      type: String,
      default: null
    },

    course: {
      type: String,
      default: null
    },
    cuisine: {
      type: String,
      default: null
    },
    video: {
      type: String,
      default: null
    },
    pageNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      postList: null,
      newFilters: false,
      pager: {},
      noPostToShow: false,
      currentDate: null,
      cssUrl: ""
    };
  },
  methods: {
    async fetchPosts() {
      let params = [];

      // params.push({
      //   filterName: "page",
      //   filterValue: pageNum
      // });

      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }
      if (this.course != null && this.course.length > 0) {
        params.push({
          filterName: "filter[course]",
          filterValue: this.course
        });
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        params.push({
          filterName: "filter[cuisine]",
          filterValue: this.cuisine
        });
      }
      if (this.video != null && this.video.length > 0) {
        params.push(
          {
            filterName: "filter[taxonomy]",
            filterValue: "chefs"
          },
          {
            filterName: "filter[term]",
            filterValue: this.video
          }
        );
      }
      const res = await CMService.getCustomPostByFilters(
        "recipe",
        params,
        12,
        this.pageNum
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.postList = res.data;
        this.noPostToShow = false;
      } else {
        this.postList = null;
        this.noPostToShow = true;
      }
    },
    handlePageFilter(page) {
      let query = {
        page: page
      };
      if (this.s) {
        query.s = this.s;
      }
      if (this.course) {
        query.course = this.course;
      }
      if (this.video) {
        query.video = this.video;
      }
      this.$router.push({
        name: this.$route.name,
        query: query
      });
    }
  },
  mounted() {
    const timestamp = new Date().getTime();
    this.currentDate = timestamp;
    const cssUrl = `/p/wp-content/themes/ali/styles-common.css?cache_buster=${this.currentDate}`;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = cssUrl;

    document.head.appendChild(link);
    const _this = this;
    _this.page = _this.$route.params.page;
    this.fetchPage("ricette");
    this.fetchPosts();
  },
  watch: {
    s() {
      this.fetchPosts();
    },
    cuisine() {
      this.fetchPosts();
    },
    course() {
      this.fetchPosts();
    },
    video() {
      this.fetchPosts();
    },
    pageNum() {
      this.fetchPosts();
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "meta.seo_title"),
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: get(
            this.page,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
